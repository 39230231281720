<template>
  <div class="compoundQuery">
    <div class="title">
      <p>
        <span>复合查询</span>
      </p>
      <div>
        <el-button @click="searchPlan('true')">新增方案</el-button>
        <el-button type="primary" @click="searchPlan">查询管理</el-button>
      </div>
    </div>
    <div class="content">
      <!--搜索-->
      <div :style="{paddingBottom: !isFold?'52px':0}" class="searchBox">
        <div class="liBox" style="display: inline-block">
          <span>查询方案： </span>
          <el-select v-model="plan" @change="planChange">
            <el-option v-for="(item,index) in planList" :key="index" :label="item.queryName"
                       :value="item.id"></el-option>
          </el-select>
          <span style="margin-left: 50px">数据间隔： </span>
          <el-select v-model="timeUnit">
            <el-option v-for="(item,index) in intervalList" :key="index" :label="item.value" :value="item.key"/>
          </el-select>
        </div>
        <div v-show="!isFold" class="liBox">
          <span>日期： </span>
          <el-select v-model="timeFlag" clearable @change="timeFlagSelect">
            <el-option label="自定义时间段" value="-1"></el-option>
            <el-option :disabled="chatType==4?false:true" label="瞬时" value="now"></el-option>
            <el-option label="近1小时" value="1H"></el-option>
            <el-option label="近12小时" value="12H"></el-option>
            <el-option label="近一天" value="1D"></el-option>
            <el-option label="近3天" value="3D"></el-option>
            <el-option label="近1周" value="1W"></el-option>
            <el-option label="本周" value="THIS_WEEK"></el-option>
            <el-option label="本月" value="1M"></el-option>
            <el-option label="本季" value="1Q"></el-option>
          </el-select>
          <el-date-picker v-model="dateTime" end-placeholder="结束时间" popper-class="date-style"
                          range-separator="→"
                          start-placeholder="开始时间" style="margin-left: 15px"
                          type="datetimerange"
                          value-format="YYYY-MM-DD HH:mm:ss">
          </el-date-picker>
          <el-checkbox-group v-model="yom" :max="1" :min="0"
                             style="display: inline-block;margin-left: 20px;transform: translateY(3px);">
            <el-checkbox :label="1">同比</el-checkbox>
            <el-checkbox :label="2">环比</el-checkbox>
          </el-checkbox-group>
        </div>
        <div v-show="!isFold" class="liBox liCopy">
          <div v-for="(item,index) in equipmentArr" :key="index" class="contentItem">
            <div :style="{visibility:equipmentArr.length!==1?'visible': 'hidden'}"
                 style="display: flex;align-items: center">
              <el-icon class="el-icon-delete delFilter"
                       style="color: #FE4761;font-size: 16px;cursor: pointer;"
                       @click="delCompound(index)">
                <Delete/>
              </el-icon>
            </div>
            <div class="liItemBox">
              <div>
                <el-input v-model="item.name" placeholder="请输入复合指标名称" style="width: 160px"></el-input>
                <el-checkbox-group v-model="item.valueType" style="margin-top: 20px;">
                  <el-checkbox :label="0">最大值</el-checkbox>
                  <el-checkbox :label="1">最小值</el-checkbox>
                </el-checkbox-group>
              </div>
              <p style="margin-left: 54px">=</p>
            </div>
            <div v-for="(item_child,index_child) in item.data" :key="index_child" class="contItemBox">
              <div v-if="index_child!==0" class="symbol">
                <p
                    :class="item.formula.substring(2*index_child-1,2*index_child)==='+'?'activeSymbol':''"
                    @click="symbolClick(index,index_child,'+')">+</p>
                <p
                    :class="item.formula.substring(2*index_child-1,2*index_child)==='-'?'activeSymbol':''"
                    @click="symbolClick(index,index_child,'-')">-</p>
                <p
                    :class="item.formula.substring(2*index_child-1,2*index_child)==='×'||item.formula.substring(2*index_child-1,2*index_child)==='*'?'activeSymbol':''"
                    @click="symbolClick(index,index_child,'*')">×</p>
                <p
                    :class="item.formula.substring(2*index_child-1,2*index_child)==='÷'||item.formula.substring(2*index_child-1,2*index_child)==='/'?'activeSymbol':''"
                    @click="symbolClick(index,index_child,'/')">÷</p>
              </div>
              <div class="contItem">
                <el-select v-model="item_child.type" clearable placeholder="请选择参数类型">
                  <el-option :value="1" label="指标"/>
                  <el-option :value="0" label="数值"/>
                </el-select>
                <el-icon v-if="item.data.length>1" class="delIcon" @click="delControl(index,index_child)">
                  <CircleClose/>
                </el-icon>
                <div v-if="item_child.type!==''" class="contBox">
                  <el-select v-show="item_child.type==1" ref="elSelectTable" v-model="item_child.typeName"
                             :filter-method="filterMethod" :popper-append-to-body="false"
                             class="select-unitName select-option-father" clearable filterable
                             popper-class="select_report_and_type">
                    <el-option :value="dataItemList">
                      <el-tree
                          ref="treeRef"
                          :expand-on-click-node=true
                          :filter-node-method="filterNode"
                          :load="treeList"
                          :props="props"
                          highlight-current
                          lazy
                          @node-click="clickUnitTreeNodeChild($event,index,index_child)"
                      />
                    </el-option>
                  </el-select>
                  <div class="center">
                    <el-input v-if="item_child.type==0" v-model="item_child.val" class="val" placeholder="请输入"
                              type="number"
                    />
                    <span v-if="item_child.type==1&& item_child.deviceName">{{ item_child.deviceName }}</span>
                    <!--                    <p v-if="item_child.type==1">{{ item_child.typeName }}</p>-->
                  </div>
                  <span v-if="item_child.type==1" class="unit">{{ item_child.unit }}</span>
                </div>
                <div v-if="item_child.type==1" class="contTime">
                  <el-checkbox v-model="item_child.difference" label="差值" size="large"/>
                </div>
              </div>
            </div>
            <div>
              <el-icon class="addIcon" @click="addControl(index)">
                <CirclePlus/>
              </el-icon>
            </div>
          </div>
        </div>
        <div v-show="!isFold" class="liBox">
          <el-button style="margin-bottom: 0;width: 160px" type="primary" @click="addCompound">添加复合指标
          </el-button>
        </div>
        <div class="liBox" style="float: right">
          <el-button class="searchButton" type="primary" @click="searchFun">查询</el-button>
          <el-button type="success" @click="exportFun">导出</el-button>
          <el-button class="searchButton resetButton" @click="resetFun">重置</el-button>
          <el-button class="searchButton resetButton" @click="enterFullScreen">全屏展示</el-button>
          <el-button v-show="isFold" class="searchButton resetButton" @click="isFold=false">
            <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/bottomArrow.png"
                 style="width: 16px;height: 16px;margin-right: 7px">
            展开
          </el-button>
          <el-button v-show="!isFold" class="searchButton resetButton" @click="isFold=true">
            <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/topArrow.png"
                 style="width: 16px;height: 16px;margin-right: 7px">
            收起
          </el-button>
        </div>
      </div>
      <!--搜索的内容-->
      <div class="contain">
        <div class="fullScreenHead">
          <p>{{ planName ? planName : '--' }}</p>
          <el-button @click="exitFullscreen"><img alt=""
                                                  src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/back.png"
                                                  style="width: 16px;height: 16px;margin-right: 7px">返回
          </el-button>
        </div>
        <div style="margin: 20px 0 30px 0">
          <span>强调展示：</span>
          <el-select v-model="strongShow" :teleported="false" multiple placeholder="请选择强调展示的数据"
                     style="width: 500px"
                     @change="strongShowChange">
            <el-option v-for="(item,index) in strongShowArr" :key="index" :label="item.name"
                       :value="item.name">
              <span :style="{backgroundColor:item.color}"
                    style="float: left;display: inline-block;width: 12px;height: 12px;border-radius: 50%;transform: translate(-7px, 11px);"></span>
              <span>{{ item.name }}</span>
            </el-option>
          </el-select>
          <span style="margin-left: 20px">展示方式：</span>
          <el-select v-model="chatType" :teleported="false" placeholder="请选择图表类型" style="width: 190px;"
                     @change="chatTypeChange">
            <el-option :value="1" label="折线图"></el-option>
            <el-option :value="0" label="柱状图"></el-option>
            <el-option :value="4" label="饼图"></el-option>
            <el-option :disabled="true" :value="2" label="面积图"></el-option>
            <el-option :disabled="true" :value="3" label="点图"></el-option>
          </el-select>
        </div>


        <el-empty v-if="echartsData.length===0"
                  class="emptyClass" description="暂无数据信息"
                  image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png"/>
        <!--图表-->
        <div v-show="echartsData.length>0" class="chart"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {h, nextTick, onMounted, reactive, ref} from "vue"
import './compoundQuery.less'
import {CloseBold, ZoomIn, Plus, Delete, CirclePlus, CircleClose} from '@element-plus/icons-vue'
import {CascaderProps, ElLoading, ElMessage, ElMessageBox} from 'element-plus'
import * as echarts from 'echarts';
import {useRouter} from 'vue-router';
import qs from "qs";
import {getQueryListApi, getQueryDetailApi, chatSearchApi, exportComDataApi} from '@/api/modules/compoundQuery'
import moment from 'moment'
import {getTreeListApi} from "@/api/modules/control";
import letterList from "@/assets/js/letterList";
import {getIntervalListApi} from "@/api/modules/dataSearch";

let router = useRouter()


// 点击跳转查询管理
const searchPlan = function (flag) {
  if (flag === 'true') {
    router.push({
      path: '/admin/compoundQueryPlan',
      query: {
        flag: true
      }
    })
  } else {
    router.push('/admin/compoundQueryPlan')
  }
}

// 搜索---------------------------------------------------------------------------------------
let plan = ref()   //选择的搜索方案
let timeFlag = ref()   //日期快捷选择
let dateTime = ref()   //搜索日期
let frequency = ref(1)   //频率
let timeUnit = ref('1h')   //频率单位
let yom = ref([])  //同环比
let equipmentArr = ref([
  {
    formula: 'A',
    name: '',
    valueType: [],
    data: [{
      val: "",
      type: '',
      unit: "",
      typeId: '',
      typeName: "",
      replaceStr: "A",
      difference: '',
      deviceId: '',
      deviceName: '',
    }]
  },
])    //搜索的设备

// 日期快捷选择事件
const timeFlagSelect = function () {
  if (timeFlag.value === '-1') {
    dateTime.value = []
  } else if (timeFlag.value === '1H') {
    dateTime.value = [moment().subtract(1, "hours").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (timeFlag.value === '12H') {
    dateTime.value = [moment().subtract(12, "hours").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (timeFlag.value === '1D') {
    dateTime.value = [moment().subtract(1, "days").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (timeFlag.value === '3D') {
    dateTime.value = [moment().subtract(3, "days").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (timeFlag.value === '1W') {
    dateTime.value = [moment().subtract(1, "weeks").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (timeFlag.value === 'THIS_WEEK') {
    dateTime.value = [moment().startOf('isoWeek').format('YYYY-MM-DD HH:mm:ss'), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (timeFlag.value === '1M') {
    dateTime.value = [moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (timeFlag.value === '1Q') {
    dateTime.value = [moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).startOf('quarter').format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
    // dateTime.value = [moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).startOf('quarter').format("YYYY-MM-DD HH:mm:ss"), moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).endOf('quarter').format("YYYY-MM-DD HH:mm:ss")]
  }
}
let chatType = ref(1)  //展示方式
let strongShow = ref([])  //强调展示


// 获取图表数据
let echartsData = ref([])
let echartsDataX = ref([])
let strongShowArr = ref([])
const getEchartsDataFun = async function () {
  echartsDataX.value = []
  echartsData.value = []
  let data = {
    startTime: dateTime.value ? dateTime.value[0] : '',
    endTime: dateTime.value ? dateTime.value[1] : '',
    frequency: Number(frequency.value),
    timeUnit: timeUnit.value,
    timeFlag: timeFlag.value,
    chatType: chatType.value,
    compositeIndexList: JSON.parse(JSON.stringify(equipmentArr.value)),
    yom: chatType.value !== 4 ? yom.value[0] : 0
  }
  // 处理差值
  for (let i = 0; i < data.compositeIndexList.length; i++) {
    // 处理最大最小值
    data.compositeIndexList[i].valueType = data.compositeIndexList[i].valueType.join()
    for (let j = 0; j < data.compositeIndexList[i].data.length; j++) {
      data.compositeIndexList[i].data[j].difference = data.compositeIndexList[i].data[j].difference ? 1 : 0
      data.compositeIndexList[i].data[j].val = Number(data.compositeIndexList[i].data[j].val)
    }
  }
  // 计算同环比时间
  if (yom.value && yom.value[0] == 1) {
    data.lastStartTime = moment(data.startTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
    data.lastEndTime = moment(data.endTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
  } else if (yom.value && yom.value[0] == 2) {
    if (timeFlag.value === '1M') {
      data.lastStartTime = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss')
      data.lastEndTime = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss')
    } else if (timeFlag.value === '1Q') {
      data.lastStartTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').startOf('quarter').format("YYYY-MM-DD HH:mm:ss")
      data.lastEndTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').endOf('quarter').format("YYYY-MM-DD HH:mm:ss")
    } else {
      let timeLength = moment(data.endTime).diff(data.startTime, 'second')
      data.lastStartTime = moment(data.startTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
      data.lastEndTime = moment(data.endTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
    }
  }
  let flag = false
  for (let i = 0; i < data.compositeIndexList.length; i++) {
    if (!data.compositeIndexList[i].name || !data.compositeIndexList[i].formula || (data.compositeIndexList[i].data.length === 0)) {
      flag = true
    } else {
      for (let j = 0; j < data.compositeIndexList[i].data.length; j++) {
        if (data.compositeIndexList[i].data[j].type === '') {
          flag = true
        }
      }
    }
  }
  // 处理符号和字母
  for (let i = 0; i < data.compositeIndexList.length; i++) {
    for (let j = 0; j < data.compositeIndexList[i].data.length; j++) {
      data.compositeIndexList[i].data[j].replaceStr = letterList()[j].name
    }
    data.compositeIndexList[i].formula = letterList()[0].name + data.compositeIndexList[i].formula.substr(1);
    for (let j = 0; j < data.compositeIndexList[i].formula.length; j++) {
      if (j % 2 == 0 && j !== 0) {
        data.compositeIndexList[i].formula = data.compositeIndexList[i].formula.substr(0, j) + letterList()[j / 2].name + data.compositeIndexList[i].formula.substr(j + 1);
      }
    }
  }
  if (chatType.value === 4) {
    yom.value = []
  }
  if (data.frequency && data.timeUnit && !flag) {
    openFullScreen()
    await chatSearchApi(data).then(res => {
      loadingAdd.value.close()
      if (res.data.code === 0) {
        // 判断是否为饼图
        if (chatType.value == 4) {
          echartsDataX.value = null
          let list = res.data.result.pieList
          for (let i = 0; i < list.length; i++) {
            list[i].name = list[i].key
            list[i].value = Number(list[i].value)
            list[i].itemStyle = {color: colorArr[i]}
            list[i].label = {formatter: `{b}: {d}%\n${list[i].time}`}
            list[i].tooltip = {formatter: `{b}: {c}<br />占比: {d}%<br />${list[i].time}`}
            strongShowArr.value.push({
              name: list[i].key,
              color: colorArr[i]
            })
          }
          echartsData.value = [{
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: true,
              lineHeight: 18
            },
            data: list
          }]
        } else {
          echartsDataX.value = res.data.result.xAxis
          echartsData.value = res.data.result.yAxis
          for (let i = 0; i < echartsData.value.length; i++) {
            echartsData.value[i].name = echartsData.value[i].deviceName + '-' + echartsData.value[i].titleName
            echartsData.value[i].sampling = 'lttb'
            for (let j = 0; j < echartsData.value[i].data.length; j++) {
              echartsData.value[i].data[j] = Number(echartsData.value[i].data[j]).toFixed(2)
            }
            // 处理最大值最小值
            echartsData.value[i].markPoint = {
              data: []
            }
            if (echartsData.value[i].valueType !== '') {
              if (echartsData.value[i].valueType === '') {
                echartsData.value[i].valueType = []
              } else {
                echartsData.value[i].valueType = echartsData.value[i].valueType.split(',')
                echartsData.value[i].valueType = echartsData.value[i].valueType.map(Number)
              }
              if (echartsData.value[i].valueType && echartsData.value[i].valueType.length > 0) {
                for (let j = 0; j < echartsData.value[i].valueType.length; j++) {
                  if (echartsData.value[i].valueType[j] === 0) {
                    echartsData.value[i].markPoint.data.push({
                      type: 'max', name: 'Max'
                    })
                  }
                  if (echartsData.value[i].valueType[j] === 1) {
                    echartsData.value[i].markPoint.data.push({
                      type: 'min', name: 'Min'
                    })
                  }
                }
              }
            }
            // 处理图表类型-----------------------------
            /* if (echartsData.value[i].chatType === 0) {
               echartsData.value[i].type = 'bar'
               echartsData.value[i].symbol = 'none'
               echartsData.value[i].barGap = '0%'
             } else if (echartsData.value[i].chatType === 1) {
               echartsData.value[i].type = 'line'
               echartsData.value[i].lineStyle = {
                 width: 1
               }
               echartsData.value[i].symbol = 'circle'
               // echartsData.value[i].symbol = 'none'
             } else if (echartsData.value[i].chatType === 3) {
               echartsData.value[i].type = 'scatter'
               echartsData.value[i].symbolSize = 15
               //for (let j = 0; j < echartsData.value[i].data.length; j++) {
               // echartsData.value[i].data.splice(j, 1, [echartsDataX.value[j], Number(echartsData.value[i].data[j])])
               //}
             } else if (echartsData.value[i].chatType === 2) {
               echartsData.value[i].type = 'line'
               echartsData.value[i].symbol = 'none'
               echartsData.value[i].areaStyle = {
                 opacity: 0.3
               }
             }*/

          }
          // 新处理图表类型------------------------------
          let isThan = false
          for (let i = 0; i < echartsData.value.length; i++) {
            // 把每条数据名称改为设备+指标
            echartsData.value[i].name = echartsData.value[i].deviceName + '-' + echartsData.value[i].titleName
            echartsData.value[i].sampling = 'lttb'
            if (echartsData.value[i].name.includes('同比') || echartsData.value[i].name.includes('环比')) {
              isThan = true
            }

            // 数据处理为小数点两位
            for (let j = 0; j < echartsData.value[i].data.length; j++) {
              echartsData.value[i].data[j] = Number(echartsData.value[i].data[j]).toFixed(2)
            }
          }
          // 图表类型样式
          // 如果是属于折线图
          if (chatType.value == 1) {
            for (let i = 0; i < echartsData.value.length; i++) {
              echartsData.value[i].type = 'line'
              // 处理同环比的不同样式
              judgmentRatio(echartsData.value[i], i, isThan)
            }
          }
          // 如果是属于柱状图
          if (chatType.value == 0) {
            for (let i = 0; i < echartsData.value.length; i++) {
              echartsData.value[i].type = 'bar'
              // 处理同环比的不同样式
              judgmentRatio(echartsData.value[i], i, isThan)
            }
          }
          for (let i = 0; i < echartsData.value.length; i++) {
            strongShowArr.value.push({
              name: echartsData.value[i].deviceName + '-' + echartsData.value[i].titleName,
              color: echartsData.value[i].color
            })
          }
        }
        nextTick(() => {
          chartLoad()
        })
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message
        })
      }
    }).catch(err => {
      console.log(err);
    })
  } else {
    ElMessage({
      type: 'info',
      message: '请选择筛选条件'
    })
  }
}


// 判断同环比方法
const judgmentRatio = function (item, i, isThan) {
  // 如果是带同环比--------------------------------------------
  if (isThan) {
    if (item.name.includes('同比') || item.name.includes('环比')) {
      // 判断当前是同环比********************
      item.color = colorArr3[Math.floor(i / 2)]
      item.lineStyle = {
        width: 3,
      }
      item.symbol = 'circle'
      item.symbolSize = 7
    } else {
      // 判断当前不是同环比********************
      item.color = colorArr[Math.floor(i / 2)]
      item.lineStyle = {
        width: 3,
        shadowColor: colorArr2[Math.floor(i / 2)],
        shadowBlur: 4
      }
      item.symbolSize = 10
    }
  } else {
    // 如果不带同环比--------------------------------------------
    item.color = colorArr[i]
    item.lineStyle = {
      width: 3,
      shadowColor: colorArr2[i],
      shadowBlur: 4
    }
    item.symbolSize = 7
  }

}
// 强调展示选择事件
let strongShowChange = function () {
  // 判断是否为饼图
  if (chatType.value === 4) {
    if (strongShow.value && strongShow.value.length === 0) {
      // 强调展示为空时
      for (let i = 0; i < echartsData.value[0].data.length; i++) {
        echartsData.value[0].data[i].itemStyle = {color: colorArr[i]}
      }
    } else {
      // 强调展示不为空时 处理强调展示的数据
      for (let i = 0; i < echartsData.value[0].data.length; i++) {
        echartsData.value[0].data[i].itemStyle = {color: '#DDDDDD'}
        for (let j = 0; j < strongShow.value.length; j++) {
          if (echartsData.value[0].data[i].name === strongShow.value[j]) {
            echartsData.value[0].data[i].itemStyle = {color: colorArr[i]}
          }
        }
      }
    }
  } else {
    if (strongShow.value && strongShow.value.length === 0) {
      // 强调展示为空时
      let isThan = false
      for (let i = 0; i < echartsData.value.length; i++) {
        if (echartsData.value[i].name.includes('同比') || echartsData.value[i].name.includes('环比')) {
          isThan = true
        }
      }
      for (let i = 0; i < echartsData.value.length; i++) {
        judgmentRatio(echartsData.value[i], i, isThan)
      }
    } else {
      // 强调展示不为空时 处理强调展示的数据
      let isThan = false
      for (let i = 0; i < echartsData.value.length; i++) {
        if (echartsData.value[i].name.includes('同比') || echartsData.value[i].name.includes('环比')) {
          isThan = true
        }
      }
      for (let i = 0; i < echartsData.value.length; i++) {
        echartsData.value[i].color = '#DDDDDD'
        echartsData.value[i].lineStyle = {
          width: 3,
        }
        for (let j = 0; j < strongShow.value.length; j++) {
          if (echartsData.value[i].name === strongShow.value[j]) {
            judgmentRatio(echartsData.value[i], i, isThan)
          }
        }
      }
    }
  }

  nextTick(() => {
    chartLoad()
  })
}
// 展示方式选择事件
const chatTypeChange = function () {
  searchFun()
}


// 查询
const searchFun = async function () {
  strongShowArr.value = []
  isFold.value = true
  await getEchartsDataFun()
}
// 重置
const resetFun = function () {
  plan.value = ''
  dateTime.value = ''
  frequency.value = 1
  timeUnit.value = ''
  timeFlag.value = ''
  yom.value = []
  equipmentArr.value = [
    {
      formula: 'A',
      name: '',
      valueType: [],
      data: [{
        val: "",
        type: '',
        unit: "",
        typeId: '',
        typeName: "",
        replaceStr: "A",
        difference: '',
        deviceId: '',
        deviceName: '',
      }]
    },
  ]

  echartsData.value = []
  echartsDataX.value = []
  nextTick(() => {
    chartLoad()
  })
}
// 搜索的数据展开还是收起
let isFold = ref(false)  //是否收起


// 加载图表
let colorArr = ['rgba(69,125,252,1)', 'rgba(189,82,244,1)', 'rgba(0,229,166,1)', 'rgba(8,205,255,1)', 'rgba(249,74,171,1)', 'rgba(254, 44, 44, 1)', 'rgba(136, 228, 49, 1)', 'rgba(255, 144, 71, 1)', 'rgba(255, 187, 0, 1)', 'rgba(33, 120, 86, 1)', 'rgba(68, 68, 93, 1)', 'rgba(29, 33, 212, 1)', 'rgba(220, 122, 30, 1)']
let colorArr2 = ['rgba(69,125,252,0.2)', 'rgba(189,82,244,0.2)', 'rgba(0,229,166,0.2)', 'rgba(8,205,255,0.2)', 'rgba(249,74,171,0.2)', 'rgba(254, 44, 44, 0.2)', 'rgba(136, 228, 49, 0.2)', 'rgba(255, 144, 71, 0.2)', 'rgba(255, 187, 0, 0.2)', 'rgba(33, 120, 86, 0.2)', 'rgba(68, 68, 93, 0.2)', 'rgba(29, 33, 212, 0.2)', 'rgba(220, 122, 30, 0.2)']
// let colorArr3 = ['rgba(69,125,252,0.4)', 'rgba(189,82,244,0.4)', 'rgba(0,229,166,0.4)', 'rgba(8,205,255,0.4)', 'rgba(249,74,171,0.4)', 'rgba(254, 44, 44, 0.4)', 'rgba(136, 228, 49, 0.4)', 'rgba(255, 144, 71, 0.4)', 'rgba(255, 187, 0, 0.4)', 'rgba(33, 120, 86, 0.4)', 'rgba(68, 68, 93, 0.4)', 'rgba(29, 33, 212, 0.4)', 'rgba(220, 122, 30, 0.4)']
let colorArr3 = ['rgba(181, 203, 254, 1)', 'rgba(229, 186, 251, 1)', 'rgba(153, 244, 219, 1)', 'rgba(156, 235, 255, 1)', 'rgba(253, 182, 222, 1)', 'rgba(255, 171, 171, 1)', 'rgba(208, 244, 173, 1)', 'rgba(255, 209, 180, 1)', 'rgba(255, 228, 153, 1)', 'rgba(166, 201, 187, 1)', 'rgba(180, 180, 190, 1)', 'rgba(164, 166, 238, 1)', 'rgba(241, 202, 165, 1)']


// 加载图表
const chartLoad = function () {
  var chartDom = document.querySelector('.chart');
  var myChart = echarts.init(chartDom);
  var option;

  option = {
    // toolbox: {
    //   feature: {
    //     dataZoom: {
    //       yAxisIndex: 'none'
    //     },
    //     restore: {},
    //     saveAsImage: {}
    //   }
    // },
    grid: {
      left: 50,
      right: 20
    },
    legend: {
      show: true,
      type: 'scroll'
    },
    tooltip: {
      trigger: chatType.value === 4 ? 'item' : 'axis', // axis   item   none三个值
      show: true
    },
    xAxis: {
      type: 'category',
      boundaryGap: chatType.value === 0,
      data: echartsDataX.value,
      show: echartsDataX.value
    },
    yAxis: {
      type: 'value',
    },
    dataZoom: [
      {
        type: 'inside',
        start: 0,
        end: 100,
        disabled: false
      },
      {
        type: 'slider',
        start: 0,
        end: 100,
        show: echartsDataX.value ? true : false
      }
    ],
    series: echartsData.value
  };
  myChart.clear()
  option && myChart.setOption(option);

  myChart.resize();
  window.onresize = () => {
    myChart.resize();
  }


  // 创建一个ResizeObserver实例
  const observer = new ResizeObserver(entries => {
    myChart.resize();
  });

// 监听目标div元素的宽度变化
  let ele = document.querySelector('.contain')
  observer.observe(ele);
}
// 加载的动画
let loadingAdd = ref()
const openFullScreen = function () {
  loadingAdd.value = ElLoading.service({
    lock: true,
    text: 'Loading',
    background: 'rgba(0, 0, 0, 0.1)'
  })
}
// 点击导出
const exportFun = function () {

  let data = {
    startTime: dateTime.value ? dateTime.value[0] : '',
    endTime: dateTime.value ? dateTime.value[1] : '',
    frequency: Number(frequency.value),
    timeUnit: timeUnit.value,
    timeFlag: timeFlag.value,
    compositeIndexList: JSON.parse(JSON.stringify(equipmentArr.value)),
    yom: yom.value[0]
  }
  // 处理差值
  for (let i = 0; i < data.compositeIndexList.length; i++) {
    // 处理最大最小值
    data.compositeIndexList[i].valueType = data.compositeIndexList[i].valueType.join()
    for (let j = 0; j < data.compositeIndexList[i].data.length; j++) {
      data.compositeIndexList[i].data[j].difference = data.compositeIndexList[i].data[j].difference ? 1 : 0
      data.compositeIndexList[i].data[j].val = Number(data.compositeIndexList[i].data[j].val)
    }
  }
  // 计算同环比时间
  if (yom.value && yom.value[0] == 1) {
    data.lastStartTime = moment(data.startTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
    data.lastEndTime = moment(data.endTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
  } else if (yom.value && yom.value[0] == 2) {
    if (timeFlag.value === '1M') {
      data.lastStartTime = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss')
      data.lastEndTime = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss')
    } else if (timeFlag.value === '1Q') {
      data.lastStartTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').startOf('quarter').format("YYYY-MM-DD HH:mm:ss")
      data.lastEndTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').endOf('quarter').format("YYYY-MM-DD HH:mm:ss")
    } else {
      let timeLength = moment(data.endTime).diff(data.startTime, 'second')
      data.lastStartTime = moment(data.startTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
      data.lastEndTime = moment(data.endTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
    }
  }
  let flag = false
  for (let i = 0; i < data.compositeIndexList.length; i++) {
    if (!data.compositeIndexList[i].name || !data.compositeIndexList[i].formula || (data.compositeIndexList[i].data.length === 0)) {
      flag = true
    } else {
      for (let j = 0; j < data.compositeIndexList[i].data.length; j++) {
        if (data.compositeIndexList[i].data[j].type === '') {
          flag = true
        }
      }
    }
  }
  // 处理符号和字母
  for (let i = 0; i < data.compositeIndexList.length; i++) {
    for (let j = 0; j < data.compositeIndexList[i].data.length; j++) {
      data.compositeIndexList[i].data[j].replaceStr = letterList()[j].name
    }
    data.compositeIndexList[i].formula = letterList()[0].name + data.compositeIndexList[i].formula.substr(1);
    for (let j = 0; j < data.compositeIndexList[i].formula.length; j++) {
      if (j % 2 == 0 && j !== 0) {
        data.compositeIndexList[i].formula = data.compositeIndexList[i].formula.substr(0, j) + letterList()[j / 2].name + data.compositeIndexList[i].formula.substr(j + 1);
      }
    }
  }
  if (data.frequency && data.timeUnit && !flag) {
    const downloadZip = (data, fileName = '数据查询') => {
      var blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
      if ('download' in document.createElement('a')) {
        var downloadElement = document.createElement('a')
        var href = window.URL.createObjectURL(blob) //创建下载的链接
        downloadElement.href = href
        downloadElement.download = fileName //下载后文件名
        document.body.appendChild(downloadElement)
        downloadElement.click() //点击下载
        document.body.removeChild(downloadElement) //下载完成移除元素
        window.URL.revokeObjectURL(href) //释放掉blob对象
      }
    }
    openFullScreen()
    exportComDataApi(data).then(res => {
      loadingAdd.value.close()
      downloadZip(res.data)
    }).catch(err => {
      console.log(err);
    })
  } else {
    ElMessage({
      type: 'info',
      message: '请选择筛选条件'
    })
  }
}

// 获取查询方案列表
let planList = ref([])
const getSearchListFun = async function () {
  await getQueryListApi(1).then(res => {
    if (res.data.code === 200) {
      isFold.value = false
      planList.value = res.data.data
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 查询方案change事件
let planName = ref()
const planChange = async function () {
  for (let i = 0; i < planList.value.length; i++) {
    if (planList.value[i].id == plan.value) {
      planName.value = planList.value[i].queryName
    }
  }
  await getQueryDetailApi(plan.value).then(res => {
    if (res.data.code === 200) {
      isFold.value = false
      frequency.value = res.data.data.frequency
      timeUnit.value = res.data.data.timeUnit
      timeFlag.value = res.data.data.timeFlag
      chatType.value = res.data.data.chatType
      yom.value = res.data.data.yom ? [res.data.data.yom] : []
      timeFlagSelect()
      if (timeFlag.value === '-1' || timeFlag.value === 'now') {
        dateTime.value = [res.data.data.startTime, res.data.data.endTime]
      }
      equipmentArr.value = res.data.data.compositeIndexList

      for (let i = 0; i < equipmentArr.value.length; i++) {
        // 处理最大值最小值
        if (equipmentArr.value[i].valueType === '') {
          equipmentArr.value[i].valueType = []
        } else {
          equipmentArr.value[i].valueType = equipmentArr.value[i].valueType.split(',')
          equipmentArr.value[i].valueType = equipmentArr.value[i].valueType.map(Number)
        }
        for (let j = 0; j < equipmentArr.value[i].data.length; j++) {
          equipmentArr.value[i].data[j].difference = equipmentArr.value[i].data[j].difference === 1 ? true : false
        }
      }
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}


// 进入全屏
const enterFullScreen = () => {
  // 获取layout的dom元素
  const parentNode = document.querySelector('.contain'); // 获取需要全屏的元素;
  document.querySelector('.contain .chart').style.height = 'calc(100% - 170px)'  //进入全屏后变大图片
  // 进入全屏
  if (parentNode.requestFullscreen) {
    parentNode.requestFullscreen();
  } else if (parentNode.webkitRequestFullScreen) {
    parentNode.webkitRequestFullScreen();
  } else if (parentNode.mozRequestFullScreen) {
    parentNode.mozRequestFullScreen();
  } else if (parentNode.msRequestFullscreen) {
    // IE11
    parentNode.msRequestFullscreen();
  }
  nextTick(() => {
    chartLoad()
  })

};
// 退出全屏
const exitFullscreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if ((document).webkitCancelFullScreen) {
    (document).webkitCancelFullScreen();
  } else if ((document).mozCancelFullScreen) {
    (document).mozCancelFullScreen();
  } else if ((document).msExitFullscreen) {
    (document).msExitFullscreen();
  }
  nextTick(() => {
    chartLoad()
  })
};


// 获取树形结构列表----------------------------------------------------------------------
// 左侧项目树元素
let treeRef = ref()
//获取项目树
const getProjectFun = async function (node, resolve) {
  await getTreeListApi(qs.stringify({
    parentId: node.data.id ? node.data.id : 0,
    type: node.data.type ? node.data.type : 1
  })).then(res => {
    if (res.data.code === 0) {
      return resolve(res.data.result)
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 项目树配置
let props = {
  label: 'name',
  children: 'subList',
  isLeaf: (data, node) => {
    if (node.data.type === 3) {
      return true
    }
  }
}
// 树级列表
const treeList = (node, resolve) => {
  getProjectFun(node, resolve)
}


// 弹窗的参数操作------------------------------------
// 符号点击
const symbolClick = function (index, index_child, e) {
  let str = equipmentArr.value[index].formula
  equipmentArr.value[index].formula = str.substr(0, 2 * index_child - 1) + e + str.substr(2 * index_child);
}
// 添加行
const addCompound = function () {
  equipmentArr.value.push({
    formula: 'A',
    name: '',
    valueType: [],
    data: [{
      val: "",
      type: '',
      unit: "",
      typeId: '',
      typeName: "",
      replaceStr: "A",
      difference: '',
      deviceId: '',
      deviceName: '',
    }]
  })
}
// 删除行
const delCompound = function (index) {
  ElMessageBox.confirm('', '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除复合指标',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {style: 'color:#595959;'}, '请确认是否要删除此复合指标，删除后数据不可恢复'),
    ])
  }).then(() => {
    equipmentArr.value.splice(index, 1)
    ElMessage({
      type: 'success',
      message: '删除成功'
    });
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '已取消删除'
    });
  });
}
// 添加参数值
const addControl = function (index) {
  equipmentArr.value[index].formula = equipmentArr.value[index].formula.concat('+D')
  equipmentArr.value[index].data.push({
    val: "",
    type: '',
    unit: "",
    typeId: '',
    typeName: "",
    replaceStr: "A",
    difference: '',
    deviceId: '',
    deviceName: '',
  })
  console.log(equipmentArr.value)
}
// 删除参数值
const delControl = function (index, index_child) {
  ElMessageBox.confirm('', '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除参数',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {style: 'color:#595959;'}, '请确认是否要删除此参数，删除后数据不可恢复'),
    ])
  }).then(() => {
    let str = equipmentArr.value[index].formula
    let strA
    let strB
    if (index_child !== 0) {
      strA = str.slice(0, 2 * index_child - 1)
      strB = str.slice(2 * index_child + 1, str.length)
    } else {
      strA = ''
      strB = str.slice(2, str.length)
    }
    equipmentArr.value[index].formula = strA.concat(strB)
    equipmentArr.value[index].data.splice(index_child, 1)
    ElMessage({
      type: 'success',
      message: '删除成功'
    });
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '已取消删除'
    });
  });
}
//node的点击
let elSelectTable = ref()
const clickUnitTreeNodeChild = function (data, index, index_child) {
  if (data.type == 3) {
    equipmentArr.value[index].data[index_child].typeName = data.name
    equipmentArr.value[index].data[index_child].typeId = data.id
    equipmentArr.value[index].data[index_child].deviceName = data.equipmentName
    equipmentArr.value[index].data[index_child].unit = data.unitName
    equipmentArr.value[index].data[index_child].deviceId = data.equipmentId
    equipmentArr.value[index].data[index_child].dictKey = data.dictKey
    elSelectTable.value[index_child].blur();
  } else {
    // ElMessage({
    //   type: 'info',
    //   message: '当前选择不是指标选项'
    // });
  }
}


// 下拉搜索
const filterMethod = function (val) {
  treeRef.value[0].filter(val)
}
const filterNode = (value, data) => {
  if (!value) return true
  return data.name.includes(value)
}

let intervalList = ref([])  //数据间隔列表
onMounted(async () => {
  await getSearchListFun()
  if (router.currentRoute.value.query.id) {
    plan.value = router.currentRoute.value.query.id
    await planChange()
    await getEchartsDataFun()
  }

  // 获取数据间隔列表
  getIntervalListApi().then(async res => {
    if (res.data.code === 0) {
      intervalList.value = res.data.result
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message,
      })
    }
  }).catch(err => {
    console.log(err);
  })

  document.addEventListener("fullscreenchange", (event) => {
    if (document.fullscreenElement) {
      document.querySelector('.fullScreenHead').style.display = 'flex'
      document.querySelector('.contain').style.paddingTop = 0
    } else {
      document.querySelector('.fullScreenHead').style.display = 'none'
      document.querySelector('.contain .chart').style.height = '400px'
      document.querySelector('.contain').style.paddingTop = '16px'
    }
  });
})

</script>

<style lang="less" scoped>
</style>
